import React, { Component } from "react";
import "../../content/assets/stopwatch.css";
import SEO from "../components/seo";

class Timer extends Component {
  constructor(props) {
    super(props);

    // fancy shit to support safari mobile
    if (typeof window !== `undefined`) {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      window.addEventListener("resize", () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      });
    }

    this.interval = null;
    this.toggleTimer = this.toggleTimer.bind(this);
    this.incrementTime = this.incrementTime.bind(this);

    this.state = {
      time: 0,
    };
  }

  toggleTimer() {
    if (this.interval !== null) {
      clearInterval(this.interval);
      this.setState(
        {
          time: 0,
        },
        () => {
          this.interval = setInterval(this.incrementTime, 1000);
        }
      );
    } else {
      this.interval = setInterval(this.incrementTime, 1000);
    }
  }

  incrementTime() {
    this.setState((prevState) => {
      return {
        time: parseInt(prevState.time, 10) + 1,
      };
    });
  }

  render() {
    const { time } = this.state;
    return (
      <div className="timerContainer">
        <SEO
          title="Full Screen Stopwatch"
          keywords={[
            `stopwatch`,
            `full screen`,
            `ipad`,
            `fire stick`,
            `single tap`,
          ]}
          description="A full screen stopwatch with easy single-tap start and reset. Perfect for TVs."
        />
        <div className="timer" onClick={this.toggleTimer}>
          {time}
        </div>
      </div>
    );
  }
}

export default Timer;
